import React from "react";
import steps from "../images/steps.svg";

const DonateInfo = () => {
  return (
    <div className="p-4">
      <img src={steps} alt="steps" />
    </div>
  );
};

export default DonateInfo;
